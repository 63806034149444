import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import authService from "../services/authService";
import Swal from "sweetalert2";
import { BiRightArrow } from "react-icons/bi";

const TokenCard = ({ doctor, setTokenData }) => {
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [doctorNotice, setDoctorNotice] = useState([]);

  const searchTokenToggle = () => {
    setShowMobileModal(!showMobileModal);
  };

  const searchToken = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const reqeustParam = {
      doctor_id: doctor.id,
      mobile: e.target.mobile.value,
    };
    const resp = authService.searchTokenDoctor(reqeustParam);
    resp
      .then(async (res) => {
        const resData = await res.json();
        setIsLoading(false);
        if (resData.success === true) {
          setTokenData(resData.data.details || []);
          setShowMobileModal(false);
        } else {
          Swal.fire({
            icon: "error",
            title: `${resData.message}`,
            text: `Time - `,
          });
        }
      })
      .catch((err) => {});
  };

  const getDoctorNotice = async () => {
    const reqeustParam = { doctor_id: doctor.id };
    const resp = authService.doctorNoticeListHome(reqeustParam);
    resp
      .then(async (res) => {
        const resData = await res.json();
        if (resData.success === true) {
          const data = resData.data;
          setDoctorNotice(data);
        } else {
          Swal.fire({
            icon: "error",
            title: `${resData.message}`,
            text: `Time - `,
          });
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getDoctorNotice();
  }, [doctor.id]);

  return (
    <div className="">
      <div className="d-flex justify-content-center my-4">
        <div
          className=" text-white text-center p-1"
          style={{
            backgroundColor: "#7ba1da",
            borderRadius: "50%",
            width: "200px",
          }}
        >
          <button className="btn btn-sm mt-4 pb-3 text-white">
            Previous
            <br />
            Token No.
          </button>
          {doctor.previous_token &&
            (doctor.previous_token?.status == null ? (
              <h4 style={{ fontSize: "3rem" }}>
                {doctor.previous_token?.token_number}
              </h4>
            ) : (
              <div>
                <h4 className="text-capitalize" style={{ fontSize: "1.5rem" }}>
                  {doctor.previous_token?.status} Token
                </h4>
                <p>T-{doctor.previous_token?.token_number}</p>
              </div>
            ))}
        </div>
        <div
          className=" text-white text-center p-1"
          style={{
            backgroundColor: "green",
            borderRadius: "50%",
            width: "200px",
            height: "240px",
            margin: "-15px 0px",
          }}
        >
          <button className="btn btn-sm mt-4 py-3 text-white">
            Current
            <br />
            Token No.
          </button>
          {doctor.current_token != "BRK" && doctor.current_token != null ? (
            doctor.current_token &&
            (doctor.current_token?.status == null ? (
              <h4 style={{ fontSize: "3rem" }}>
                {doctor.current_token.token_number}
              </h4>
            ) : (
              <div>
                <h4 className="text-capitalize" style={{ fontSize: "1.5rem" }}>
                  {doctor.current_token?.status} Token
                </h4>
                <p>T-{doctor.current_token.token_number}</p>
              </div>
            ))
          ) : (
            <h4
              style={{
                fontSize: "1.5rem"
              }}
            >
              {doctor.next_token && doctor.next_token.token_number != 1
                ? "It's Break"
                : "Waiting for start"}
            </h4>
          )}
        </div>
        <div
          className=" text-white text-center p-1"
          style={{
            width: "200px",
            backgroundColor: "#56565a",
            borderRadius: "50%",
          }}
        >
          <button className="btn btn-sm text-white mt-4 pb-3">
            Next
            <br />
            Token No.
          </button>
          {doctor.next_token &&
            (doctor.next_token?.status == null ? (
              <h4 style={{ fontSize: "3rem" }}>
                {doctor.next_token?.token_number}
              </h4>
            ) : (
              <div>
                <h4 className="text-capitalize" style={{ fontSize: "1.5rem" }}>
                  {doctor.next_token?.status} Token
                </h4>
                <p>T-{doctor.next_token?.token_number}</p>
              </div>
            ))}
        </div>
      </div>
      <div className="d-flex mb-2 justify-content-center">
        <div>
          <button
            onClick={() => searchTokenToggle()}
            className="ms-3 btn my-1 m-0 btn-warning px-4"
          >
            <span className="blink">Click here to track your token</span>
          </button>
        </div>
      </div>
      <div>
        {doctorNotice && (
          <h6
            className="mt-3"
            style={{
              color: "red",
            }}
          >
            Doctor Notice -:
          </h6>
        )}
        <hr className="my-2" style={{ borderColor: "red" }} />
        {doctorNotice &&
          doctorNotice.map((item, index) => (
            <p
              className={`${index % 2 == 0 ? "text-success" : "text-danger"}`}
              key={item.id}
            >
              <BiRightArrow /> {item?.message}
            </p>
          ))}
      </div>

      <Modal show={showMobileModal} onHide={searchTokenToggle}>
        <Modal.Body>
          <h4>Track your token details</h4>
          <hr />
          <form onSubmit={searchToken}>
            <label>Mobile Number</label>
            <input
              type="text"
              name="mobile"
              className="form-control"
              placeholder="Enter Your Mobile Number"
            />
            <div className="mt-3 d-flex justify-content-end">
              <Button className="btn-dark" onClick={searchTokenToggle}>
                close
              </Button>
              <Button
                type="submit"
                className="me-1 btn-info"
                disabled={isLoading}
              >
                {isLoading && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}{" "}
                Submit
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TokenCard;
