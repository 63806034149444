import React from "react";
import { Route, Routes } from "react-router-dom";
import { Login } from "../auth/Login";
import { Ragister } from "../auth/Ragister";
import { Verification } from "../auth/Verification";

export const AuthRoute = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/ragister" element={<Ragister />} />
        <Route path="/ragister/verification/:emailid" element={<Verification />} />
        </Routes>
    </>
  );
};
