import React, { useEffect, useState } from "react";
import { Dashboard } from "./Dashboard";
import { AUTH_TOKEN, USER_DETAILS, baseURl } from "../../utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import FeeModel from "./components/FeeModel";
import { FaRegEdit } from "react-icons/fa";
import { Col, Input } from "antd";
import { BiSearch } from "react-icons/bi";
import Utils from "../../utils/Utils";
import Head from "./components/Head";

export const ReportData = () => {
  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [shifts, setShift] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [tokenid, setSetTokenId] = useState(false);
  const [fees, setFees] = useState(0);
  const [today, setToday] = useState(null);
  const [formData, setFormData] = useState({
    fromDate: "",
    toDate: "",
  });
  const [permissions, setPermissions] = useState({
    edit: 0,
    fee: 0,
  });
  const userPer = JSON.parse(localStorage.getItem("role_permissions") || "[]");

  const handleShowModal = (token_id, fees) => {
    setSetTokenId(token_id);
    setFees(fees);
    setShowModal(true); // Show modal
  };
  const handleCloseModal = () => setShowModal(false); // Close modal

  const handleSubmit = (e) => {
    e.preventDefault();
    fatchList();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fatchList = async () => {
    try {
      // Make the API call
      setIsLoading(true);
      formData.shift_id =
        localStorage.getItem("shift_id") ?? USER_DETAILS.active_shift_id;
      const response = await axios.post(`${baseURl}/doctor/report`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      });

      if (response.status === 200) {
        setList(response.data.data);
        setAllList(response.data.data);
        setIsLoading(false);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  const getShiftList = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${baseURl}/doctor/listShift`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setShift(response.data.data);
        setIsLoading(false);
        if (response.data.data.length == 1) {
          localStorage.setItem("shift_id", response.data.data[0].id);
        }
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching the API:", error);
    }
  };

  const refreshData = () => {
    setTimeout(() => {
      fatchList();
      refreshData();
    }, 60000);
  };

  const reSetForm = () => {
    var todate = Utils.todayDate();
    setFormData({
      ...formData,
      ["fromDate"]: todate,
      ["toDate"]: todate,
    });
    setToday(todate);
    fatchList();
  };

  useEffect(() => {
    getShiftList();
    fatchList();
    reSetForm();
    if (userPer) {
      // console.log(userPer);

      const editPermission = userPer.some((perm) => perm.id === 4);
      const feePermission = userPer.some((perm) => perm.id === 6);

      // console.log(addPermission);
      setPermissions({
        edit: editPermission,
        fee: feePermission,
      });
    }
  }, []);

  const editToken = (token_id) => {
    navigate(`../admin/token/edit/${token_id}`);
  };

  const onSearch = (e) => {
    //console.log(e.currentTarget.value);
    const value = e.currentTarget.value;
    const searchArray = e.currentTarget.value ? allList : allList;
    const data = Utils.wildCardSearch(searchArray, value);
    setList(data);
  };

  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "60px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <div className="main-container">
            <hr className="d-block d-lg-none" />
            <Head />
            <div className="reportContainer my-3 ps-3 pe-4 pe-2">
              <div className="">
                <form className="row mb-3" onSubmit={handleSubmit}>
                  <div className="col-md-3 form-group">
                    <label htmlFor="fromDate" className="k-label">
                      From:
                    </label>
                    <input
                      type="date"
                      name="fromDate"
                      className="form-control"
                      value={formData.fromDate}
                      onChange={(e) => handleInputChange(e)}
                      pattern="dd-mm-yyyy"
                    />
                  </div>
                  <div className="col-md-3 form-group">
                    <label htmlFor="toDate" className="k-label">
                      To:
                    </label>
                    <input
                      type="date"
                      name="toDate"
                      className="form-control"
                      value={formData.toDate}
                      onChange={(e) => handleInputChange(e)}
                      pattern="dd-mm-yyyy"
                    />
                  </div>
                  {shifts.length > 1 && (
                    <div className="col-md-2 form-group">
                      <label htmlFor="Shift" className="k-label">
                        Select Shift
                      </label>
                      <select
                        className="form-control"
                        placeholder="Shift Name"
                        onChange={(e) => handleInputChange(e)}
                        name="shift_id"
                        value={formData.shift_id}
                      >
                        {shifts.map((item, i) => (
                          <option value={item.id} key={`shift-${i}`}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="col-md-4">
                    <button
                      type="submit"
                      className="btn btn-primary px-lg-4 mt-4 me-2"
                      disabled={isLoading}
                    >
                      {isLoading && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}{" "}
                      Submit
                    </button>
                    <button
                      onClick={() => reSetForm()}
                      type="reset"
                      className="btn btn-info px-lg-4 mt-4"
                    >
                      Reset
                    </button>
                  </div>
                </form>
              </div>
              <hr />
              <div className="">
                <div className="my-4">
                  <div className="d-flex flex-wrap justify-content-between">
                    <h4>Today Patient</h4>
                    <Col className="text-end mb-2" xs={24} sm={24} md={6}>
                      <Input
                        placeholder="Search"
                        prefix={<BiSearch />}
                        onChange={(e) => onSearch(e)}
                      />
                    </Col>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered mt-3 w-100">
                      <thead>
                        <tr>
                          <th>S.N.</th>
                          <th>Name</th>
                          <th>Mobile/Age</th>
                          <th>Token</th>
                          <th>Time</th>
                          <th>Fee</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item, index) => (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.name ?? "Auto generate"}</td>
                            <td>{item.mobile ?? "NO"}</td>
                            <td>{item.token}</td>
                            <td>{item.time}</td>
                            <td>{item.is_paid}</td>
                            <td>{item.is_type}</td>
                            <td className="d-flex">
                              {!item.is_online &&
                                today == item.today &&
                                permissions.edit > 0 && (
                                  <button
                                    disabled={isLoading}
                                    className="btn btn-warning btn-sm"
                                    onClick={() => editToken(item.id)}
                                    title="edit"
                                  >
                                    <FaRegEdit />
                                  </button>
                                )}
                              {!item.is_paid &&
                                item.name &&
                                today == item.today &&
                                permissions.fee > 0 && (
                                  <button
                                    className="btn btn-info ms-1 btn-sm"
                                    onClick={() =>
                                      handleShowModal(item.id, item.fees)
                                    }
                                  >
                                    Fee
                                  </button>
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {fees != 0 && (
          <FeeModel
            show={showModal}
            handleClose={handleCloseModal}
            tokenid={tokenid}
            fees={fees}
            getPatientList={fatchList}
          />
        )}
      </div>
    </div>
  );
};
