import React, { useEffect, useState } from "react";
import Head from "./components/Head";
import { Dashboard } from "./Dashboard";
import Header from "./Header";
import { AUTH_TOKEN, BASE_URL } from "../../utils";
import axios from "axios";
import Swal from "sweetalert2";
import Utils from "../../utils/Utils";

const MessageSend = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allList, setAllList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [template, setTemplate] = useState([]);
  const [formData, setFormData] = useState({
    template_id: "",
    template_type: "Today Patient",
    from_date: null,
    to_date: null,
    parameters: [],
  });

  const changeLanguage = (e) => {
    const is_type = e.target.value;
    const filteredList = allList.filter((list) => list.is_type == is_type);
    setTemplateList(filteredList);
  };

  const fatchList = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${BASE_URL}/template/list`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      if (response.status === 200) {
        const data = response.data.data;
        const filteredList = data.filter((list) => list.is_type == "English");
        setTemplateList(filteredList);
        setAllList(data);
        setIsLoading(false);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name != "template_type") {
      var data = formData.parameters;
      data[name] = value;
      setFormData({
        ...formData,
        ["parameters"]: data,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name == "template_id") {
      const filterData = Utils.filterArray(templateList, "id", Number(value));
      setTemplate(filterData[0]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios
      .post(`${BASE_URL}/doctor/send-message`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "message send",
          text: "Your message has been sent to all patients.",
        });
        const setData = {
          template_id: "",
          template_type: "TodayPatient",
          parameters: [],
        };
        setTemplate([]);
        setFormData(setData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error adding token", error.response.data.message);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error?.response?.data?.message,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fatchList();
  }, []);

  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "60px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <div className="main-container">
            <hr className="d-block d-lg-none" />
            <Head />
            <div className="reportContainer">
              <h4 className="py-0 ps-3">Send Messages</h4>
              <hr className="my-0" />
              <form className="py-2 px-3" onSubmit={handleSubmit}>
                <div className="row my-3">
                  <div class="form-group col-md-2">
                    <label for="">Language</label>
                    <select
                      class="form-control"
                      name="is_type"
                      required
                      onChange={(e) => changeLanguage(e)}
                    >
                      <option value="English">English</option>
                      <option value="Hindi">Hindi</option>
                    </select>
                  </div>
                  <div className="col-md-10">
                    <label className="mb-0">Template</label>
                    <select
                      className="form-control"
                      onChange={(e) => handleSelectChange(e)}
                      name="template_id"
                      value={formData.template_id}
                    >
                      <option value="">Select Template</option>
                      {templateList.map((item, i) => (
                        <option value={item.id} key={`template-${i}`}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {template.description && (
                  <div
                    className="text-danger p-3 mt-2"
                    style={{ border: "1px solid black" }}
                  >
                    {template?.description}
                  </div>
                )}
                <div className="row mb-3">
                  {template.parameters &&
                    JSON.parse(template.parameters).map((item, i) => (
                      <div className="col-md-4" key={`perams-${i}`}>
                        <label className="mb-0">
                          {item.title}{" "}
                          <span className="text-danger fs-5">*</span>
                        </label>
                        <input
                          type={item.type}
                          className="form-control"
                          placeholder={`Enter ${item.title}`}
                          onChange={(e) => handleInputChange(e)}
                          name={i}
                          required
                        />
                      </div>
                    ))}
                </div>

                <div className="col-md-4 mb-3">
                  <label className="mb-0">Send Message to</label>
                  <select
                    className="form-control"
                    onChange={(e) => handleInputChange(e)}
                    name="template_type"
                    value={formData.template_type}
                  >
                    <option value="Today Patient">Today All Patient</option>
                    <option value="Tomorrow Patient">Tomorrow All Patient</option>
                    <option value="All Patient">All Patient</option>
                    <option value="Custom"> Custom </option>
                  </select>
                </div>
                {formData.template_type == "Custom" && (
                  <div className="row">
                    <div className="col-md-4">
                      <label className="mb-0">
                        From Date<span className="text-danger fs-5">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => handleSelectChange(e)}
                        name="from_date"
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="mb-0">
                        To Date<span className="text-danger fs-5">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => handleSelectChange(e)}
                        name="to_date"
                        required
                      />
                    </div>
                  </div>
                )}

                <div className="mt-3 text-end">
                  <button
                    disabled={isLoading}
                    className="btn btn-primary ms-2 px-5"
                    type="submit"
                  >
                    {isLoading && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageSend;
