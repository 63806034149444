class Utils {
  static wildCardSearch(list, input) {
    const searchText = (item) => {
      for (let key in item) {
        if (item[key] == null) {
          continue;
        }
        if (
          item[key]
            .toString()
            .toUpperCase()
            .indexOf(input.toString().toUpperCase()) !== -1
        ) {
          return true;
        }
      }
    };
    list = list.filter((value) => searchText(value));
    return list;
  }

  static filterArray(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] === value);
    }
    return data;
  }

  static todayDate() {
    var today = new Date();
    var day =
      today.getDate().toString().length == 1
        ? "0" + today.getDate()
        : today.getDate();
    var month =
      (today.getMonth() + 1).toString().length == 1
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1;

    return today.getFullYear() + "-" + month + "-" + day;
  }
}

export default Utils;
